<template>
  <v-row class="pb-10" v-scroll="onScroll">
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="updatedView" outlined>
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" color="blue-500" v-bind="attrs" v-on="on">
              <v-icon left>mdi-calendar-blank </v-icon>{{filter[selected]}}<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="selected = 'this_day'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Hoy</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selected = 'last_day'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Ayer</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selected = 'this_week'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Esta semana</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selected = 'last_week'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Semana pasada</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selected = 'this_month'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Este mes</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selected = 'last_month'" :ripple="false">
              <v-list-item-title class="body-2 grey-500--text">Mes pasado</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="pa-0">
      <v-row no-gutters>
        <v-col>
          <v-row>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'this_day'" :elevation="hover ? 5 : selected === 'this_day' ? 8 : 0" >
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Hoy</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">
                      {{ ((cards?.this_day?.OBS ?? 0) + (cards?.this_day?.APR ?? 0) + (cards?.this_day?.REJ ?? 0) + (cards?.this_day?.DUP ?? 0)) | number }}
                    </span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.this_day?.OBS ?? 0) + (cards?.this_day?.APR ?? 0)) | number}}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.this_day?.REJ ?? 0) + (cards?.this_day?.DUP ?? 0))| number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'this_week'" :elevation="hover ? 5 : selected === 'this_week' ? 8 : 0" flat>
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Esta semana</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">
                      {{ ((cards?.this_week?.OBS ?? 0) + (cards?.this_week?.APR ?? 0) + (cards?.this_week?.REJ ?? 0) + (cards?.this_week?.DUP ?? 0)) | number }}
                    </span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.this_week?.OBS ?? 0) + (cards?.this_week?.APR ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{((cards?.this_week?.REJ ?? 0) + (cards?.this_week?.DUP ?? 0))}} </span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'this_month'" :elevation="hover ? 5 : selected === 'this_month' ? 8 : 0" flat>
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Este mes</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">
                      {{ ((cards?.this_month?.OBS ?? 0) + (cards?.this_month?.APR ?? 0) + (cards?.this_month?.REJ ?? 0) + (cards?.this_month?.DUP ?? 0)) | number }}
                    </span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.this_month?.OBS ?? 0) + (cards?.this_month?.APR ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.this_month?.REJ ?? 0) + (cards?.this_month?.DUP ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'last_day'" :elevation="hover ? 5 : selected === 'last_day' ? 8 : 0" flat>
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Ayer</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">
                      {{ ((cards?.last_day?.OBS ?? 0) + (cards?.last_day?.APR ?? 0) + (cards?.last_day?.REJ ?? 0) + (cards?.last_day?.DUP ?? 0)) | number }}
                    </span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_day?.OBS ?? 0) + (cards?.last_day?.APR ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_day?.REJ ?? 0) + (cards?.last_day?.DUP ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'last_week'" :elevation="hover ? 5 : selected === 'last_week' ? 8 : 0" flat>
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Semana pasada</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">
                      {{ ((cards?.last_week?.OBS ?? 0) + (cards?.last_week?.APR ?? 0) + (cards?.last_week?.REJ ?? 0) + (cards?.last_week?.DUP ?? 0)) | number }}
                    </span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_week?.OBS ?? 0) + (cards?.last_week?.APR ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_week?.REJ ?? 0) + (cards?.last_week?.DUP ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card :class="[`pa-4 rounded-md`]" @click="selected = 'last_month'" :elevation="hover ? 5 : selected === 'last_month' ? 8 : 0" flat>
                  <v-col class="text-center pa-0">
                    <span class="text-subtitle-2 grey-700--text">Mes pasado</span>
                    <span class="d-block mt-2 font-weight-bold text-h4 grey-700--text">{{ ((cards?.last_month?.OBS ?? 0) + (cards?.last_month?.APR ?? 0) + (cards?.last_month?.REJ ?? 0) + (cards?.last_month?.DUP ?? 0)) | number }}</span>
                  </v-col>
                  <div>
                    <span class="d-block pt-2">
                      <span :class="[`blue-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_month?.OBS ?? 0) + (cards?.last_month?.APR ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Aceptados</span>
                    </span>
                    <span class="d-block">
                      <span :class="[`red-500--text font-weight-bold mr-1`, $vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-h6']">{{ ((cards?.last_month?.REJ ?? 0) + (cards?.last_month?.DUP ?? 0)) | number }}</span>
                      <span :class="$vuetify.breakpoint.width <= 1250 ? 'text-body-1' : 'text-subtitle-2'">Rechazados</span>
                    </span>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="pl-6">
          <v-card flat>
            <v-card-title class="pa-0">
              <v-toolbar class="" color="transparent" flat height="46">
                <v-toolbar-title class="px-5 d-flex align-center subtitle-2 grey-500--text font-weight-semibold"><v-icon left color="grey-700" size="20">mdi-chart-bar</v-icon>Documentos procesados</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-4 px-4">
              <v-card flat outlined>
                <v-card-text :style="$vuetify.breakpoint.width < 1250 ? 'min-height: 70px' : ''">
                  <div class="d-flex">
                    <div>
                      <v-avatar class="mr-1" color="blue-500" :size="8" /> Aceptado
                    </div>
                    <div class="ml-5">
                      <v-avatar class="mr-1" color="red-500" :size="8" /> Rechazado
                    </div>
                  </div>
                  <v-col :class="[`pt-5`, $vuetify.breakpoint.width < 1250 ? 'pb-0' : 'pb-5']" class="text-center">
                    <v-progress-circular
                      v-if="loading"
                      :size="180"
                      :width="25"
                      indeterminate
                      color="grey-50"
                    ></v-progress-circular>
                    <template v-else>
                      <chart-doughnut class="doughnut" :chart-data="totalDonut() >= 1 ? chartDonut : chartDonutEmpty" :colors="totalDonut() > 1 ? ['#2466CB', '#BE2D2F'] : ['#F4F6FA', '#F4F6FA']" :tooltips="totalDonut() >= 1" :height="$vuetify.breakpoint.width < 1250 ? 170 : 180" />
                      <span class="doughnut__text-doughnut text-center text-h5 grey-700--text font-weight-bold">
                        {{ totalDonut(true) }}
                      </span>
                    </template>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card :height="450" flat>
            <v-card-title class="pa-0">
              <v-toolbar class="" color="transparent" flat height="46">
                <v-toolbar-title class="px-5 d-flex align-center subtitle-2 grey-500--text font-weight-semibold"><v-icon left color="grey-700" size="20">mdi-file-document-multiple</v-icon>Tipo de documentos </v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <skeleton-list v-if="loading" />
              <template v-else>
                <v-empty-state class="mt-16" v-if="!documents.length" type="documents" id="documentos" customClass="mt-n6" :isFree="true" title="Aún no tienes documentos para mostrar" :height="280" />
                <v-simple-table class="no-hover table-not-rounded" v-else dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left grey-700--text font-weight-medium">Tipo de documento</th>
                        <th class="text-end grey-700--text font-weight-medium" style="min-width: 40px;">Aceptados</th>
                        <th class="text-end grey-700--text font-weight-medium" style="min-width: 40px;">Rechazados</th>
                        <th class="text-end grey-700--text font-weight-medium" style="min-width: 50px;">Total</th>
                      </tr>
                      <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -8px" />
                    </thead>
                    <tbody>
                      <tr v-for="item in documents" :key="item.id">
                        <td class="body-2">
                          <span class="d-inline-block text-truncate ml-n1" style="max-width: 100%;">{{item.code | nameDocumentsType}}</span>
                        </td>
                        <td class="text-end body-2">
                          {{item.totalA | number}}
                        </td>
                        <td class="text-end body-2">
                        {{item.totalR | number}}
                        </td>
                        <td class="text-end body-2" style="min-width: 180px;">
                          {{ (item.totalA + item.totalR) | number }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card flat :height="450">
            <v-card-title class="pa-0">
              <v-toolbar class="" color="transparent" flat height="46">
                <v-toolbar-title class="px-5 d-flex align-center subtitle-2 grey-500--text font-weight-semibold"><v-icon left color="grey-700" size="20">mdi-chart-bar</v-icon>Métricas de los tipo de documentos</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-divider />
            <v-card-text class="pt-4 pb-0 px-4">
              <v-card flat outlined>
                <v-card-text :style="$vuetify.breakpoint.width < 1250 ? 'min-height: 70px' : ''">
                  <div class="d-flex">
                    <div>
                      <v-avatar class="mr-1" color="blue-500" :size="8" /> Aceptado
                    </div>
                    <div class="ml-5">
                      <v-avatar class="mr-1" color="red-500" :size="8" /> Rechazado
                    </div>
                  </div>
                  <v-col class="pt-5 pb-0">
                    <chart-bar
                      class="d-block mt-4"
                      :chart-data="chartBarData"
                      :height="285"
                      style="position:relative"
                    />
                  </v-col>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import ListViewMixin from '@/mixins/ListViewMixin'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import { groupBy } from 'lodash'
import ChartBar from '@/components/charts/CharBar'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    MiniHeader,
    ChartDoughnut,
    VEmptyState,
    ChartBar,
    SkeletonList
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin
  ],
  data: () => ({
    selected: 'this_day',
    filter: {
      this_day: 'Hoy',
      last_day: 'Ayer',
      this_week: 'Esta semana',
      last_week: 'Semana pasada',
      this_month: 'Este mes',
      last_month: 'Mes pasado'
    },
    cards: {},
    scroll: 0,
    loading: false,
    chartDonut: {
      labels: ['Aceptado', 'Rechazado'],
      datasets: []
    },
    chartDonutEmpty: {
      labels: [],
      datasets: [
        {
          data: [100]
        }
      ]
    },
    chartBarData: {
      labels: [],
      datasets: [
        {
          data: []
        },
        {
          data: []
        }
      ]
    },
    documents: [],
    countAccepted: [],
    countRejected: [],
    dtes: {
      PE09: 'G.rem. remit.',
      PE31: 'G.rem. trasp.',
      PE01: 'Factura',
      PE03: 'Boleta venta',
      PE04: 'Liqui. Compra',
      PE07: 'Nota crédito',
      PE08: 'Nota débito',
      PE20: 'Comp. recep.',
      PE40: 'Comp. percep.',
      PER1: 'Rec. honorarios',
      PER7: 'NC. recibos',
      CL33: 'Factura',
      CL34: 'Factura exenta',
      CL39: 'Boleta',
      CL41: 'Boleta exenta',
      CL43: 'Liquidación fact.',
      CL46: 'Fact. compra',
      CL52: 'Guía de despacho',
      CL56: 'Nota de débito',
      CL61: 'Nota de crédito',
      CL110: 'Factura de exp.',
      CL112: 'N.C  de exp.',
      CL111: 'N.D de exp.'
    }
  }),
  computed: {
    totalDonut () {
      return (isAbbreviation = false) => {
        const total = ((this.documents[this.documents.length - 1]?.countAccepted ?? 0) + (this.documents[this.documents.length - 1]?.countRejected ?? 0)) || 0
        return isAbbreviation ? total.toString().length <= 6 ? this.formatNumber2(total) : (total / Math.pow(10, (Math.floor((Math.log10(total)) / 3) * 3))).toFixed(2) + ["", "", "M", "B", "T"][Math.floor(Math.log10(total) / 3)] : total ?? 0
      }
    },
    breadcrumbs () {
      return {
        main: 'Métricas',
        children: [
          { text: this.$route.meta.title }
        ]
      }
    },
    currentRoute () {
      return this.$route.name === 'MetricsList' ? 'gen' : 'rec'
    }
  },
  watch: {
    '$route' () {
      this.updatedView()
    },
    selected (val) {
      this.$router.replace({ query: { q: val} }).catch(() => {})
    },
    currentRoute () {
      this.selected = 'this_day'
    }
  },
  created () {
    this.updatedView()
    this.selected = this.$route?.query?.q ?? 'this_day'
  },
   methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    updatedView () {
      this.getData()
      this.getDataGraph(this.selected)
    },
    getDataGraph () {
      this.countAccepted = []
      this.countRejected = []
      this.documents = []
      this.loading = true
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (this.$route.query.q) {
        delete query.q
      }

      this.$store.dispatch('branches/LIST', {
        resource: `metrics/${this.currentRoute}/summary/${this.$route.query.q || 'this_day'}`,
        query: query
      })
      .then((resp) => {
        Object.entries(resp.data).forEach(([key, value]) => {
          if (Object.keys(value)?.length) {
            Object.entries(value).forEach(([k, v]) => {
              if (['OBS', 'APR'].includes(k)) {
                const foundIndex = this.countAccepted?.findIndex(({type}) => type === key)
                if (foundIndex >= 0) {
                 this.countAccepted[foundIndex].count += v
                } else {
                  this.countAccepted.push({count: v, type: key, key: 'accepted'})
                }
              } else {
                const foundIndex = this.countRejected?.findIndex(({type}) => type === key)
                if (foundIndex >= 0) {
                 this.countRejected[foundIndex].count += v
                } else {
                  this.countRejected.push({count: v, type: key, key: 'rejected'})
                }
              }
            })
          }
        })
        this.chartDonut = {
          ...this.chartDonut,
          datasets: [{ data: [this.countAccepted?.map(({count}) => count)?.reduce((ac, elm) => ac + elm, 0) ?? 0, this.countRejected?.map(({count}) => count)?.reduce((ac, elm) => ac + elm, 0) ?? 0] }]
        }

        let cAccepted = []
        let cRejected = []
        Object.entries(groupBy([...this.countAccepted, ...this.countRejected], 'type')).forEach(([key, value]) => {
          cAccepted.push(value.filter(({key}) => key === 'accepted').map(({count}) => count)?.reduce((ac, elm) => ac + elm, 0) ?? 0)
          cRejected.push(value.filter(({key}) => key === 'rejected').map(({count}) => count)?.reduce((ac, elm) => ac + elm, 0) ?? 0)
          this.documents.push({ type: this.dtes[key], code: key, totalA: value.filter(({key}) => key === 'accepted').map(({count}) => count)[0] ?? 0, totalR: value.filter(({key}) => key === 'rejected').map(({count}) => count)[0] ?? 0, countAccepted: cAccepted?.reduce((ac, elm) => ac + elm, 0) ?? 0, countRejected: cRejected?.reduce((ac, elm) => ac + elm, 0) ?? 0 })
        })
        let acceptedList = []
        let rejectList = []
        let labels = []
        this.documents.forEach((item) => {
          acceptedList.push({data: [item.totalA]})
          rejectList.push({data: [item.totalR]})
          labels = [...labels, item.type]
        })
        this.chartBarData = {
          ...this.chartBarData,
          labels: labels
        }
        this.chartBarData.datasets[0] = {data: acceptedList.map(({data}) => data).flat()}
        this.chartBarData.datasets[1] = {data: rejectList.map(({data}) => data).flat()}
      })
      .finally(() => {
        this.loading = false
      })
    },
    getData () {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (this.$route.query.q) {
        delete query.q
      }

      this.$store.dispatch('branches/LIST', {
        resource: `metrics/${this.currentRoute}/summary`,
        query: query
      })
      .then((resp) => {
        this.cards = resp.data
      })
    }
  }
}
</script>
